<template>
	<div>
		<div class="d-flex justify-content-between">
			<div></div>
			<div class="d-flex justify-content-center" v-if="availableWidgetsDropdown.length > 1">
				<b-form-select :options="availableWidgetsDropdown" v-model="selectedWidget" />
				<button
					:disabled="selectedWidget == null"
					class="btn btn-success ml-5"
					@click="addWidget()"
				>
					Add
				</button>
			</div>

			<button
				:class="['btn', layoutNotSaved ? 'btn-success' : 'btn-secondary']"
				@click="saveLayout()"
				:disabled="!layoutNotSaved"
			>
				Save Layout
				<span
					class="svg-icon svg-icon-sm svg-icon-warning"
					v-if="layoutNotSaved"
					v-b-tooltip="'Layout NOT Saved'"
				>
					<inline-svg src="/media/svg/icons/Code/Warning-1-circle.svg" />
				</span>
				<b-spinner small v-if="layoutSaving" />
			</button>
		</div>

		<GridLayout
			v-if="layout.length > 0"
			:layout.sync="layout"
			:col-num="4"
			:row-height="100"
			:is-draggable="true"
			:is-resizable="true"
			:vertical-compact="true"
			:use-css-transforms="true"
			class="vue-grid-layout"
		>
			<GridItem
				class="vue-grid-item"
				v-for="(l, idx) in layout"
				v-bind:key="idx"
				:static="false"
				:x="l.x"
				:y="l.y"
				:w="l.w"
				:h="l.h"
				:i="l.i"
				:maxH="1"
				@resized="gridChangeEvent"
				@moved="gridChangeEvent"
			>
				{{ adminSite.data.layout.widgets[l.i].Name }}
				<span class="font-italic" v-if="l.properties && l.properties.label">{{
					l.properties.label
				}}</span>
				<span
					:class="[
						'svg-icon',
						'svg-icon-sm',
						l.properties && Object.keys(l.properties).length != 0
							? 'svg-icon-success'
							: 'svg-icon-dark',
					]"
					@click="dataToModal(l.fullWidget)"
					v-b-modal="'widgetModal-' + l.fullWidget.WidgetId"
					v-b-tooltip.bottom="'Edit Widget Properties'"
					v-if="adminSite.data.layout.widgets[l.i].PropertiesOption"
				>
					<inline-svg src="/media/svg/icons/Code/Settings4.svg" />
				</span>
				<span
					id="removewidget"
					@click="removeWidget(l.i)"
					class="svg-icon svg-icon-sm svg-icon-dark"
					style="cursor: pointer"
					v-b-tooltip.bottom="'Remove widget from layout'"
				>
					<inline-svg src="/media/svg/icons/Navigation/Close.svg" />
				</span>
			</GridItem>
		</GridLayout>

		<b-modal
			size="xl"
			id="widgetModal-2"
			@ok="ok()"
			v-if="widModalData.properties && widModalData.WidgetId == 2"
		>
			<template #modal-title>Edit iDirect Graphs Widget properties</template>
			<h3>{{ widModalData.Name }}</h3>
			<table>
				<tr>
					<td style="text-align: right">Alternate Modem Name:</td>
					<td><b-form-input type="text" size="40" v-model="widModalData.properties.label" /></td>
				</tr>
				<tr>
					<td></td>
					<td>
						<span class="small"
							>Useful for demo sites. Will effect all widgets for this modem on this site.</span
						>
					</td>
				</tr>
			</table>
			<div class="row">
				<div class="col-md-6">
					<br />
					<b>Select graph tabs to include:</b>
					<table class="table">
						<tr>
							<td style="text-align: right">IP Traffic</td>
							<td><b-form-checkbox v-model="widModalData.properties.traffic" /></td>
						</tr>
						<tr>
							<td style="text-align: right">Sat Traffic</td>
							<td><b-form-checkbox v-model="widModalData.properties.sattraffic" /></td>
						</tr>
						<tr>
							<td style="text-align: right">ICMP</td>
							<td>
								<b-form-checkbox v-model="widModalData.properties.icmp" />
								<label>
									Threshold:<b-form-input
										type="text"
										size="sm"
										v-model="widModalData.properties.icmpthreshold"
									/><small>0=disabled</small></label
								>
							</td>
						</tr>
						<tr>
							<td style="text-align: right">SNR</td>
							<td>
								<label>
									Threshold Down:<b-form-input
										type="text"
										size="sm"
										v-model="widModalData.properties.snrthresholds.down"
									/><small>0=disabled</small></label
								>
							</td>
							<td>
								<label>
									Threshold Up:<b-form-input
										type="text"
										size="sm"
										v-model="widModalData.properties.snrthresholds.up"
									/><small>0=disabled</small></label
								>
							</td>
						</tr>
						<tr>
							<td style="text-align: right">Power</td>
							<td><b-form-checkbox v-model="widModalData.properties.power" /></td>
						</tr>
						<tr>
							<td style="text-align: right">Modem Temp</td>
							<td><b-form-checkbox v-model="widModalData.properties.temp" /></td>
						</tr>
						<tr>
							<td style="text-align: right">Symbol Offset</td>
							<td><b-form-checkbox v-model="widModalData.properties.symoff" /></td>
						</tr>
						<tr>
							<td style="text-align: right">Uptime</td>
							<td><b-form-checkbox v-model="widModalData.properties.uptime" /></td>
						</tr>
						<tr>
							<td style="text-align: right">MODCOD</td>
							<td><b-form-checkbox v-model="widModalData.properties.modcod" /></td>
						</tr>
						<tr>
							<td style="text-align: right">CRC Errors</td>
							<td><b-form-checkbox v-model="widModalData.properties.errors" /></td>
						</tr>
					</table>
					<br /><br />
					<div
						v-if="
							interfaceoptions.length > 0 &&
							widModalData.Name.indexOf('Response Time') === -1 &&
							widModalData.Name.indexOf('CPU Load') === -1 &&
							widModalData.Name.indexOf('Availability') === -1
						"
					>
						<b>Select additional interface to overlay on traffic (4G/LTE):</b>
						<b-form-select
							name="lte4gint"
							class="form-control"
							width="4"
							v-model="widModalData.properties.lte4gint"
							:options="interfaceoptions"
						/>
					</div>
				</div>
				<div class="col-md-6">
					<br />
					<b>Options:</b>
					<table class="table">
						<tr>
							<td style="text-align: right">
								<span
									v-b-tooltip="
										'Show Max traffic values AND Average traffic values on the IP or Sat Traffic graphs'
									"
									>Show Max Traffic Values</span
								>
							</td>
							<td>
								<b-form-checkbox
									v-model="widModalData.properties.maxtraffic"
									v-b-tooltip="
										'Show Max traffic values AND Average traffic values on the IP or Sat Traffic graphs'
									"
								/>
							</td>
						</tr>
						<tr>
							<td style="text-align: right">
								<span
									v-b-tooltip="
										'Replace average traffic with max traffic  (overrides Show Max Traffic Values)'
									"
									>Show Max Traffic ONLY</span
								>
							</td>
							<td>
								<b-form-checkbox
									v-model="widModalData.properties.onlymaxtraffic"
									v-b-tooltip="
										'Replace average traffic with max traffic (overrides Show Max Traffic Values)'
									"
								/>
							</td>
						</tr>
						<tr>
							<td style="text-align: right">
								<span v-b-tooltip="'Hide CIR/MIR Selection button'"
									>Enable Show CIR/MIR button</span
								>
							</td>
							<td><b-form-checkbox v-model="widModalData.properties.showcirmir" /></td>
						</tr>
					</table>

					<div v-if="ipslaoptions.length > 0 && widModalData.properties.ipslaonlatencytabvals">
						<br /><br />
						<b>Show IP SLA on Latency tab:</b><br />
						<b-form-checkbox v-model="widModalData.properties.ipslaonlatencytab">
							Enabled</b-form-checkbox
						><br />
						<label
							>Select IP SLA:<b-form-select
								:options="ipslaoptions"
								v-model="widModalData.properties.ipslasource" /></label
						><br />
						<b-form-checkbox
							v-model="widModalData.properties.ipslaonlatencytabvals.rtt"
							:disabled="!widModalData.properties.ipslasource"
						>
							RTT</b-form-checkbox
						>
						<br />

						<b-form-checkbox
							v-model="widModalData.properties.ipslaonlatencytabvals.jitter"
							:disabled="!widModalData.properties.ipslasource"
						>
							Jitter
						</b-form-checkbox>
						<br />

						<b-form-checkbox
							v-model="widModalData.properties.ipslaonlatencytabvals.lossDS"
							:disabled="!widModalData.properties.ipslasource"
						>
							Loss DS
						</b-form-checkbox>
						<br />

						<b-form-checkbox
							v-model="widModalData.properties.ipslaonlatencytabvals.lossSD"
							:disabled="!widModalData.properties.ipslasource"
						>
							Loss SD
						</b-form-checkbox>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal size="lg" id="widgetModal-4" @ok="ok()" v-if="widModalData.properties">
			<template #modal-title>Edit iDirect Details Widget properties</template>
			<h3>{{ widModalData.Name }}</h3>
			<b>Select information to include:</b>
			<table class="table">
				<tr>
					<td style="text-align: right">iDirect Availability</td>
					<td><b-form-checkbox v-model="widModalData.properties.availability" /></td>
				</tr>
				<tr v-if="ipslaoptions.length > 0">
					<td>Use IP SLA also for Availability:</td>

					<td>
						<b-form-select :options="ipslaoptions" v-model="widModalData.properties.ipslasource" />
						<span
							class="svg-icon svg-icon-sm svg-icon-dark"
							v-b-tooltip="'Disable using IP SLA'"
							@click="disableIPSLAAvail()"
						>
							<inline-svg src="/media/svg/icons/Navigation/Close.svg" />
						</span>
					</td>
				</tr>
			</table>
		</b-modal>

		<b-modal size="lg" id="widgetModal-19" @ok="ok()" v-if="widModalData.properties">
			<template #modal-title>Edit Exinda Widget properties</template>
			<h3>{{ widModalData.Name }}</h3>
			<b>Select subnets to include:</b>

			<table class="table">
				<tr>
					<td style="text-align: right">Select a VLAN</td>
					<td>
						<b-form-select
							multiple
							v-model="widModalData.properties.exinda"
							:options="exindaOptions"
						/>
						<br />
						<span class="small">Hold Ctrl/Cmd to select multiple</span>
					</td>
				</tr>
			</table>
			<br />
			<b>Manually enter Exinda subnets</b><br />
			<span class="small"> ex: 192.168.101.0/24;192.168.201.1/32</span><br />
			<b-form-input type="text" size="70" v-model="widModalData.properties.exindasubnetmanual" />
		</b-modal>

		<b-modal
			size="lg"
			id="widgetModal-22"
			@ok="ok()"
			v-if="widModalData.properties && widModalData.properties.interfacestotal"
		>
			<template #modal-title>Edit Solarwinds Graph Widget properties</template>
			<h3>{{ widModalData.Name }}</h3>
			<table style="width: 100%">
				<tr>
					<td style="text-align: right">Alternate Widget Label:</td>
					<td>
						<b-form-input type="text" v-model="widModalData.properties.label" />
					</td>
				</tr>
			</table>
			<br />
			<span class="bold">Show Min/Max bps:</span>
			<table style="width: 100%; table-layout: fixed">
				<tr>
					<td colspan="2">In Legend:</td>
					<td colspan="2">In Graph (overrides <i>In Legend</i>):</td>
				</tr>
				<tr>
					<td>
						<b-form-checkbox v-model="widModalData.properties.showminlegend">Min</b-form-checkbox>
					</td>
					<td>
						<b-form-checkbox v-model="widModalData.properties.showmaxlegend"> Max</b-form-checkbox>
					</td>
					<td>
						<b-form-checkbox v-model="widModalData.properties.showmingraph"> Min </b-form-checkbox>
					</td>
					<td>
						<b-form-checkbox v-model="widModalData.properties.showmaxgraph">Max </b-form-checkbox>
					</td>
				</tr>
			</table>
			<br />
			<div
				v-if="
					interfaceoptions.length > 0 &&
					widModalData.Name.indexOf('Response Time') === -1 &&
					widModalData.Name.indexOf('CPU Load') === -1 &&
					widModalData.Name.indexOf('Availability') === -1
				"
			>
				<b>Select additional interfaces to included in total:</b>
				<div v-for="d in interfaceoptionsNotThisOne" v-bind:key="d.value">
					<b-form-checkbox v-model="widModalData.properties.interfacestotal[d.value]">{{
						d.text
					}}</b-form-checkbox
					><br />
				</div>
			</div>
		</b-modal>

		<b-modal
			size="lg"
			id="widgetModal-24"
			@ok="ok()"
			v-if="widModalData.properties && widModalData.properties.ipsla"
		>
			<template #modal-title>Edit Solarwinds IP SLA Widget properties</template>
			<h3>{{ widModalData.Name }}</h3>
			<table style="width: 60%">
				<tr>
					<td style="text-align: right">Alternate Widget Label:</td>
					<td><b-form-input type="text" size="40" v-model="widModalData.properties.label" /></td>
				</tr>
				<tr>
					<td><br /></td>
					<td></td>
				</tr>
				<tr>
					<td style="text-align: right">Threshold Line:</td>
					<td>
						<b-form-input type="text" size="20" v-model="widModalData.properties.threshold" /><small
							>Set to 0 to disable</small
						>
					</td>
				</tr>
			</table>
			<br />
			<b>Select stats to show on graph for this IP SLA:</b>
			<table class="table">
				<tr>
					<td style="text-align: right">RTT</td>
					<td>
						<b-form-checkbox v-model="widModalData.properties.ipsla[widModalData.DeviceId].rtt" />
					</td>
				</tr>
				<tr>
					<td style="text-align: right">Jitter</td>
					<td>
						<b-form-checkbox
							v-model="widModalData.properties.ipsla[widModalData.DeviceId].jitter"
						/>
					</td>
				</tr>
				<tr>
					<td style="text-align: right">Loss DS</td>
					<td>
						<b-form-checkbox
							v-model="widModalData.properties.ipsla[widModalData.DeviceId].lossDS"
						/>
					</td>
				</tr>
				<tr>
					<td style="text-align: right">Loss SD</td>
					<td>
						<b-form-checkbox
							v-model="widModalData.properties.ipsla[widModalData.DeviceId].lossSD"
						/>
					</td>
				</tr>
			</table>

			<div ng-show="ipslaarray.length > 0">
				<b>Select stats to show on graph for other IP SLA's:</b>

				<div v-for="d in ipslaoptionsNotThisOne" v-bind:key="d.DeviceId">
					{{ d.Name }}
					<table class="table">
						<tr>
							<td style="text-align: right">RTT</td>
							<td><b-form-checkbox v-model="widModalData.properties.ipsla[d.DeviceId].rtt" /></td>
						</tr>
						<tr>
							<td style="text-align: right">Jitter</td>
							<td>
								<b-form-checkbox v-model="widModalData.properties.ipsla[d.DeviceId].jitter" />
							</td>
						</tr>
						<tr>
							<td style="text-align: right">Loss DS</td>
							<td>
								<b-form-checkbox v-model="widModalData.properties.ipsla[d.DeviceId].lossDS" />
							</td>
						</tr>
						<tr>
							<td style="text-align: right">Loss SD</td>
							<td>
								<b-form-checkbox v-model="widModalData.properties.ipsla[d.DeviceId].lossSD" />
							</td>
						</tr>
					</table>
				</div>
			</div>
			<div>
				<b-form-checkbox v-model="widModalData.properties.showName"
					><b>Show Device Name in Legend </b></b-form-checkbox
				>
			</div>
		</b-modal>

		<b-modal
			size="lg"
			id="widgetModal-29"
			@ok="ok()"
			v-if="widModalData.properties && widModalData.properties.swcomtechinterfaces"
		>
			<template #modal-title>Edit Comtech Interfaces properties</template>
			<h3>{{ widModalData.Name }}</h3>
			<div v-if="swcomtechint.length > 0">
				<b>Select Comtech Interfaces to include on graph:</b>
				<div v-for="d in swcomtechint" v-bind:key="d.RowID">
					<b-form-checkbox
						v-model="widModalData.properties.swcomtechinterfaces[widModalData.DeviceId][d.RowID]"
					>
						{{ d.RowID }}</b-form-checkbox
					><br />
				</div>
			</div>
		</b-modal>

		<b-modal
			size="lg"
			id="widgetModal-33"
			@ok="ok()"
			v-if="widModalData.properties && widModalData.WidgetId == 33"
		>
			<template #modal-title>Edit Solarwinds Daily Graph Widget properties</template>
			<h3>{{ widModalData.Name }}</h3>
			<br />
			<table style="width: 80%">
				<tr>
					<td style="text-align: right">Alternate Widget Label:</td>
					<td><b-form-input type="text" size="40" v-model="widModalData.properties.label" /></td>
				</tr>
			</table>
			<br />

			<div v-if="interfaceoptions.length > 0">
				<b>Select additional interfaces to included in total:</b>
				<div v-for="d in interfaceoptions" v-bind:key="d.value">
					<b-form-checkbox v-model="widModalData.properties.interfacestotal[d.value]"
						>{{ d.text }} </b-form-checkbox
					><br />
				</div>
			</div>
		</b-modal>

		<b-modal
			size="lg"
			id="widgetModal-35"
			@ok="ok()"
			v-if="widModalData.properties && widModalData.WidgetId == 35"
		>
			<template #modal-title>Edit Solarwinds NetFlow Widget properties</template>
			<h3>{{ widModalData.Name }}</h3>
			<b>Select subnets to include:</b>

			<table class="table">
				<tr>
					<td style="text-align: right">Select a VLAN</td>
					<td>
						<b-form-select
							multiple
							v-model="widModalData.properties.netflow"
							:options="exindaOptions"
						/>
						<br />
						<span class="small">Hold Ctrl/Cmd to select multiple</span>
					</td>
				</tr>
			</table>
			<br />
			<b>Manually enter Netflow subnets</b><br />
			<span class="small"> ex: 192.168.101.0/24;192.168.201.1/32</span><br />
			<b-form-input type="text" size="70" v-model="widModalData.properties.netflowsubnetmanual" />
		</b-modal>

		<b-modal
			size="lg"
			id="widgetModal-36"
			@ok="ok()"
			v-if="widModalData.properties && widModalData.WidgetId == 36"
		>
			<template #modal-title>Edit Solarwinds Comtech Map Widget properties</template>
			<h3>{{ widModalData.Name }}</h3>
			<div v-if="swcomtechmaplist.length > 0">
				<b>Select Modems to include on Map:</b>
				<div class="mt-10" v-for="d in swcomtechmaplist" v-bind:key="d.DeviceId">
					<b-form-checkbox
						v-if="d.properties.remote && d.properties.remote.name"
						v-model="widModalData.properties.swcomtechmap[d.DeviceId]['remote']"
					>
						Remote: {{ d.properties.remote.name }} Lat: {{ d.properties.remote.lat }} Lon:
						{{ d.properties.remote.lon }} </b-form-checkbox
					><br />

					<b-form-checkbox
						v-if="d.properties.hub && d.properties.hub.name"
						v-model="widModalData.properties.swcomtechmap[d.DeviceId]['hub']"
					>
						Hub: {{ d.properties.hub.name }} Lat: {{ d.properties.hub.lat }} Lon:
						{{ d.properties.remote.lon }} </b-form-checkbox
					><br />
					<br />
				</div>
			</div>
			<div v-if="swcomtechmaplist.length == 0" class="alert alert-danger bold">
				Click on Device Properties
				<span class="svg-icon svg-icon-sm svg-icon-dark">
					<inline-svg src="/media/svg/icons/Code/Settings4.svg" />
				</span>
				of the SW Comtech in Devices section to add Geocoordinates to enable modem location map.
			</div>
			<div v-if="swcomtechmapmnclist.length != 0">
				Select MNC PC to pull geocoordinates from:<br />

				<b-form-select v-model="widModalData.properties.mncpc" :options="swcomtechmapmnclist" />
				<span v-if="widModalData.properties.mncpc"
					>Alternate MNC PC Map Marker Label:
					<b-form-input type="text" size="70" v-model="widModalData.properties.mncpclabel" />
				</span>
			</div>
		</b-modal>

		<b-modal size="lg" id="widgetModal-46" @ok="ok()" v-if="widModalData.properties && widModalData.properties.ciscoumbrella">
			<template #modal-title>Edit Cisco Umbrella Widget properties</template>
			<h3>{{ widModalData.Name }}</h3>
			<table class="table">
				<tr>
					<td style="text-align: right">Show Allowed/Blocked Activity Table</td>
					<td><b-form-checkbox v-model="widModalData.properties.ciscoumbrella.activity" /></td>
				</tr>
			</table>
		</b-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mask2cider, createNetmaskAddr } from '@/helpers.js';
import VueGridLayout from 'vue-grid-layout';
import { REFRESH_ADMINEDIT_SITE, SET_AVAILABLE_WIDGETS } from '@/core/services/store/admin.module';

export default {
	name: 'SiteLayout',
	props: ['activeTab'],
	data() {
		return {
			layout: [],
			layoutSaving: false,
			layoutNotSaved: false,
			availableWidgets: [],
			selectedWidget: null,
			widModalData: {},
			swcomtechint: [],
			swcomtechmaplist: [],
			swcomtechmapmnclist: [],
		};
	},
	methods: {
		loadData() {
			this.availableWidgets = [];
			if (!this.adminSite.data.layout.gridster && this.adminSite.data.layout.widgets) {
				var rawlayout = [];
				for (var i = 0; i < this.adminSite.data.layout.widgets.length; i++) {
					rawlayout.push({ col: 0, row: i, sizeX: 4, sizeY: 1, WidgetArrId: i });
				}
				this.layoutNotSaved = true;
				this.layout = this.layoutConverter(rawlayout);
			} else {
				this.layout = this.layoutConverter(this.adminSite.data.layout.gridster);
			}

			this.adminSite.data.layout.widgets.forEach((w, wi) => {
				var widgetInLayout = false;
				for (let g of this.layout) {
					if (g.WidgetArrId == wi) {
						widgetInLayout = true;
						break;
					}
				}
				if (!widgetInLayout) {
					this.availableWidgets.push(wi);
				}
			});
			this.$store.commit(SET_AVAILABLE_WIDGETS, this.availableWidgets);

			// filter out widgets in layout but no device - legacy issues
			this.layout = this.layout.filter(
				(g) =>
					this.availableWidgets.includes(g.WidgetArrId) ||
					this.adminSite.data.layout.widgets.filter((w, wi) => wi === g.WidgetArrId).length != 0
			);
		},
		gridChangeEvent() {
			this.layoutNotSaved = true;
		},
		layoutConverter(gl) {
			var l = [];
			gl.forEach((g) => {
				l.push({
					x: g.col,
					y: g.row,
					w: g.sizeX,
					h: g.sizeY,
					i: g.WidgetArrId,
					WidgetArrId: g.WidgetArrId,
					DeviceId: this.adminSite.data.layout.widgets[g.WidgetArrId].DeviceId,
					WidgetId: this.adminSite.data.layout.widgets[g.WidgetArrId].WidgetId,
					fullWidget: this.adminSite.data.layout.widgets[g.WidgetArrId],
					properties: this.adminSite.data.layout.widgets[g.WidgetArrId]
						? this.adminSite.data.layout.widgets[g.WidgetArrId].properties
						: {},
				});
			});
			return l;
		},
		gridConvert() {
			var l = [];
			this.layout.forEach((g) => {
				l.push({
					col: g.x,
					row: g.y,
					sizeX: g.w,
					sizeY: g.h,
					WidgetArrId: g.WidgetArrId,
					DeviceId: g.DeviceId,
					WidgetId: g.WidgetId,
				});
			});
			return l;
		},
		saveLayout() {
			var gl = this.gridConvert();
			if (gl.length > 0) {
				this.layoutSaving = true;
				this.$http
					.post(`sitelayout/${this.adminSite.id}`, { SiteId: this.adminSite.id, layout: gl })
					.then((sl) => {
						this.layoutSaving = false;
						this.layoutNotSaved = false;
					});
			}
		},
		removeWidget(i) {
			this.layout = this.layout.filter((l) => {
				if (l.WidgetArrId === i) {
					this.availableWidgets.push(i);
					return false;
				}
				return true;
			});
			this.layoutNotSaved = true;
		},
		addWidget() {
			// get max row
			var mrow = 0;
			for (let l of this.layout) {
				if (l.y > mrow) {
					mrow = l.y;
				}
			}
			mrow++;
			this.layout.push({
				x: 0,
				y: mrow,
				w: 4,
				h: 1,
				i: this.selectedWidget,
				WidgetArrId: this.selectedWidget,
				DeviceId: this.adminSite.data.layout.widgets[this.selectedWidget].DeviceId,
				WidgetId: this.adminSite.data.layout.widgets[this.selectedWidget].WidgetId,
				fullWidget: this.adminSite.data.layout.widgets[this.selectedWidget],
				properties: {},
			});
			this.availableWidgets = this.availableWidgets.filter((f) => f !== this.selectedWidget);
			this.layoutNotSaved = true;
		},
		dataToModal(item) {
			this.widModalData = JSON.parse(JSON.stringify(item));

			if (!this.widModalData.properties) {
				if (this.widModalData.WidgetId == 2) {
					// default iDirect Graphs selections
					this.widModalData.properties = {
						traffic: true,
						sattraffic: false,
						icmp: true,
						snr: true,
						power: true,
						temp: true,
						symoff: true,
						uptime: true,
						modcod: true,
						errors: false,
					};
				} else {
					this.widModalData.properties = {};
				}
			}
			// iDirect Graphs
			if (this.widModalData.WidgetId == 2) {
				if (!this.widModalData.properties.ipslaonlatencytabvals) {
					this.widModalData.properties.ipslaonlatencytabvals = {};
				}
				if (!this.widModalData.properties.snrthresholds) {
					this.widModalData.properties.snrthresholds = {};
				}
			}
			// Exinda
			if (this.widModalData.WidgetId == 19) {
				if (!this.widModalData.properties.exinda) {
					this.widModalData.properties.exinda = [];
				} else {
					// pull out manually subnets
					this.widModalData.properties.exindasubnetmanual = '';
					for (var ii = 0; ii < this.widModalData.properties.exinda.length; ii++) {
						if (this.widModalData.properties.exinda[ii].description == 'Manual') {
							if (this.widModalData.properties.exindasubnetmanual != '') {
								this.widModalData.properties.exindasubnetmanual += ';';
							}
							this.widModalData.properties.exindasubnetmanual +=
								this.widModalData.properties.exinda[ii].IpAddr +
								'/' +
								mask2cider(this.widModalData.properties.exinda[ii].SubNet);
						}
					}
					// reverse through array removing manual elements
					for (var ii = this.widModalData.properties.exinda.length - 1; ii > -1; ii--) {
						if (this.widModalData.properties.exinda[ii].description == 'Manual') {
							this.widModalData.properties.exinda.splice(ii, 1);
						}
					}
				}
			}
			// Solarwinds
			if (this.widModalData.WidgetId == 22) {
				if (!this.widModalData.properties.interfacestotal) {
					this.widModalData.properties.interfacestotal = {};
				}
			}
			// SW IP SLA
			if (this.widModalData.WidgetId == 24) {
				if (!this.widModalData.properties.ipsla) {
					// none are set
					this.widModalData.properties.ipsla = {};
					this.widModalData.properties.ipsla[this.widModalData.DeviceId] = {};
					// initialize options for additional IP SLA
					this.ipslaoptionsNotThisOne.forEach((ii) => {
						this.widModalData.properties.ipsla[ii.DeviceId] = {};
					});
				} else {
					// some are set, init others
					if (!this.widModalData.properties.ipsla[this.widModalData.DeviceId]) {
						this.widModalData.properties.ipsla[this.widModalData.DeviceId] = {};
					}
					this.ipslaoptionsNotThisOne.forEach((ii) => {
						if (!this.widModalData.properties.ipsla[ii.DeviceId]) {
							this.widModalData.properties.ipsla[ii.DeviceId] = {};
						}
					});
				}
			}
			// Solarwinds Comtech
			if (this.widModalData.WidgetId == 29) {
				if (!this.widModalData.properties.swcomtechinterfaces) {
					this.widModalData.properties.swcomtechinterfaces = {};
					this.widModalData.properties.swcomtechinterfaces[this.widModalData.DeviceId] = {};
				}
				var device = null;
				for (let d of this.adminSite.data.devices) {
					if (d.DeviceId == this.widModalData.DeviceId) {
						device = d;
						break;
					}
				}
				if (device) {
					this.$http.get(`swcomtech/interfaces/${device.Source}`).then((resp) => {
						this.swcomtechint = resp.data.data.data;
					});
				}
			}
			// Solarwinds Daily Graph
			if (this.widModalData.WidgetId == 33) {
				if (!this.widModalData.properties.interfacestotal) {
					this.widModalData.properties.interfacestotal = [];
				}
			}
			// Netflow
			if (this.widModalData.WidgetId == 35) {
				if (!this.widModalData.properties.netflow) {
					this.widModalData.properties.netflow = [];
				} else {
					// pull out manually subnets
					this.widModalData.properties.netflowsubnetmanual = '';
					for (var ii = 0; ii < this.widModalData.properties.netflow.length; ii++) {
						if (this.widModalData.properties.netflow[ii].description == 'Manual') {
							if (this.widModalData.properties.netflowsubnetmanual != '') {
								this.widModalData.properties.netflowsubnetmanual += ';';
							}
							this.widModalData.properties.netflowsubnetmanual +=
								this.widModalData.properties.netflow[ii].IpAddr +
								'/' +
								mask2cider(this.widModalData.properties.netflow[ii].SubNet);
						}
					}
					// reverse through array removing manual elements
					for (var ii = this.widModalData.properties.netflow.length - 1; ii > -1; ii--) {
						if (this.widModalData.properties.netflow[ii].description == 'Manual') {
							this.widModalData.properties.netflow.splice(ii, 1);
						}
					}
				}
			}
			// Comtech Map
			if (this.widModalData.WidgetId == 36) {
				this.swcomtechmaplist = [];
				if (!this.widModalData.properties.swcomtechmap) {
					this.widModalData.properties.swcomtechmap = {};
				}
				for (let n of this.adminSite.data.devices) {
					if (n.TypeId == 13) {
						if (n.properties != null) {
							this.swcomtechmaplist.push(n);
						}
					}
				}
				this.swcomtechmapmnclist = [];
				this.$http.get('swmncpclist').then((res) => {
					res.data.data.forEach((mnc) => {
						this.swcomtechmapmnclist.push({ value: mnc.NodeID, text: mnc.Caption });
					});
				});
			}
			// Cisco Umbrella
			if (this.widModalData.WidgetId == 46) {
				if (!this.widModalData.properties.ciscoumbrella) {
					this.widModalData.properties.ciscoumbrella = {
						activity: true
					};
				} 
			}
		},
		// WidgetId 4
		disableIPSLAAvail() {
			this.widModalData.properties.ipslasource = null;
		},
		// Widget 33 Solarwinds Daily Graph copy settings from main interface - took this feature out
		copyFromMain(devId) {
			for (let w of this.adminSite.data.layout.widgets) {
				if (w.DeviceId == devId) {
					if (w.properties) {
						if (w.properties.interfacestotal) {
							this.widModalData.properties.interfacestotal = w.properties.interfacestotal;
						}
						if (w.properties.label) {
							this.widModalData.properties.label = w.properties.label;
						}
					}
				}
			}
		},
		ok() {
			// Exinda Manual field
			if (this.widModalData.WidgetId == 19) {
				if (
					this.widModalData.properties.exindasubnetmanual &&
					this.widModalData.properties.exindasubnetmanual != ''
				) {
					var manArray = this.widModalData.properties.exindasubnetmanual.split(';');
					for (var ii = 0; ii < manArray.length; ii++) {
						var ipnet = manArray[ii].split('/');
						if (typeof ipnet[1] === 'undefined') {
							alert(manArray[ii] + ' invalid subnet');
							return;
						}
						var subnet = createNetmaskAddr(ipnet[1]);
						this.widModalData.properties.exinda.push({
							VlanId: 1,
							IpAddr: ipnet[0],
							SubNet: subnet,
							description: 'Manual',
						});
					}
					delete this.widModalData.properties.exindasubnetmanual;
				}
			}

			// Netflow Manual field
			if (this.widModalData.WidgetId == 35) {
				if (
					this.widModalData.properties.netflowsubnetmanual &&
					this.widModalData.properties.netflowsubnetmanual != ''
				) {
					var manArray = this.widModalData.properties.netflowsubnetmanual.split(';');
					for (var ii = 0; ii < manArray.length; ii++) {
						var ipnet = manArray[ii].split('/');
						if (typeof ipnet[1] === 'undefined') {
							alert(manArray[ii] + ' invalid subnet');
							return;
						}
						var subnet = createNetmaskAddr(ipnet[1]);
						this.widModalData.properties.netflow.push({
							VlanId: 1,
							IpAddr: ipnet[0],
							SubNet: subnet,
							description: 'Manual',
						});
					}
					delete this.widModalData.properties.netflowsubnetmanual;
				}
			}
			this.$http
				.post(
					`widgetproperties/${this.adminSite.id}/${this.widModalData.DeviceId}/${this.widModalData.WidgetId}`,
					this.widModalData.properties
				)
				.then(() => {
					// update properties in case they update it again before refresh
					for (var i = 0; i < this.layout.length; i++) {
						if (
							this.layout[i].WidgetId == this.widModalData.WidgetId &&
							this.layout[i].DeviceId == this.widModalData.DeviceId
						) {
							this.layout[i].properties = this.widModalData.properties;
							this.layout[i].fullWidget.properties = this.widModalData.properties;

							break;
						}
					}

					this.activeTab = 1;
				});
		},
	},
	components: {
		GridLayout: VueGridLayout.GridLayout,
		GridItem: VueGridLayout.GridItem,
	},
	mounted() {
		this.loadData();
	},
	computed: {
		...mapGetters(['adminSite', 'deviceTypes']),
		availableWidgetsDropdown() {
			var dd = this.availableWidgets
				// skip where name not set
				.filter((f) => this.adminSite.data.layout.widgets[f].Name)
				.map((i) => {
					return { text: this.adminSite.data.layout.widgets[i].Name, value: i };
				});
			dd.unshift({ text: 'Select Widget...', value: null });
			return dd;
		},
		// WidgetId 2
		interfaceoptions() {
			return this.adminSite.data.devices
				.filter((d) => {
					return (
						d.TypeId == 10 &&
						d.Source.indexOf('CPU Load') === -1 &&
						d.Source.indexOf('Response Time') === -1
					);
				})
				.map((i) => {
					return { value: i.DeviceId, text: i.Name };
				});
		},
		// WidgetId 2 & 4
		ipslaoptions() {
			return this.adminSite.data.devices
				.filter((d) => {
					return d.TypeId == 11;
				})
				.map((i) => {
					return { value: i, text: i.Name };
				});
		},
		// WidgetId 19
		exindaOptions() {
			return this.adminSite.data.exinda_options.map((ex) => {
				return { value: ex, text: ex.description };
			});
		},
		// WidgetId 22
		interfaceoptionsNotThisOne() {
			return this.adminSite.data.devices
				.filter((d) => {
					return (
						d.TypeId == 10 &&
						d.Source.indexOf('CPU Load') === -1 &&
						d.Source.indexOf('Response Time') === -1 &&
						d.DeviceId !== this.widModalData.DeviceId
					);
				})
				.map((i) => {
					return { value: i.DeviceId, text: i.Name };
				});
		},
		// WidgetId 24
		ipslaoptionsNotThisOne() {
			return this.adminSite.data.devices.filter((d) => {
				return d.TypeId == 11 && d.DeviceId != this.widModalData.DeviceId;
			});
		},
	},
};
</script>

<style>
/*.vue-grid-layout {

}*/

.vue-grid-item:not(.vue-grid-placeholder) {
	background: #ccc;
	border: 1px solid #999;
}
.vue-grid-item .resizing {
	opacity: 0.9;
}
.vue-grid-item .static {
	background: #cce;
}
.vue-grid-item .text {
	font-size: 24px;
	text-align: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: 100%;
	width: 100%;
}
.vue-grid-item .no-drag {
	height: 100%;
	width: 100%;
}
.vue-grid-item .minMax {
	font-size: 12px;
}
.vue-grid-item .add {
	cursor: pointer;
}
.vue-grid-item #removewidget {
	position: absolute;
	top: -2px;
	right: 4px;
}
.vue-draggable-handle {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 0;
	left: 0;
	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
		no-repeat;
	background-position: bottom right;
	padding: 0 8px 8px 0;
	background-repeat: no-repeat;
	background-origin: content-box;
	box-sizing: border-box;
	cursor: pointer;
}
</style>